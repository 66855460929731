.locationInfoSection {
	display: grid;
	grid-template-rows: repeat(3, auto);
	gap: 2rem;
}

@media (--breakpoint-not-small) {
	.locationInfoSection {
		grid-template-rows: 1fr;
		grid-template-columns: 1fr 3px 1fr 3px 1fr;
	}
}
